import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";
import Leaderboard from "../../components/Shared/Leaderboard";
import { useLeaderboard, useQuestion } from "../../contexts/ProgrammeContext";
import GameProgressIndicator from "../Game/components/GameProgressIndicator";
import GameQuestionHeader from "../Game/components/GameQuestionHeader";
import LeaderboardTitle from "./LeaderboardTitle";

const GameLeaderboard = () => {
  const { current, total } = useQuestion();
  const { leaderboard } = useLeaderboard();
  return (
    <Container>
      <GameQuestionHeader>
        <LeaderboardTitle>Leaderboard</LeaderboardTitle>
        <GameProgressIndicator current={current} total={total} />
      </GameQuestionHeader>
      <div
        className={css`
          flex: 1;
        `}
      >
        <Leaderboard leaderboard={leaderboard} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default GameLeaderboard;
