import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Back from "components/Back";
import { useGame } from "contexts/ProgrammeContext";
import React from "react";

const GameShare = ({ onBack }) => {
  const { game } = useGame();
  return (
    <Container>
      <div
        className={css`
          border-radius: 20px;
          overflow: hidden;
          width: 216px;
          height: 216px;
        `}
      >
        <img src={game?.qrcode?.qrCode} alt="qr-code" width="216px" height="216px" />
      </div>
      <div
        className={css`
          text-align: center;
          padding: 0 20%;
          margin-top: 32px;
          font-weight: 700;
          font-size: 14px;
          color: #ffffff;
        `}
      >
        Your friends can scan this code to join the game
      </div>
      <Icon onClick={onBack}>
        <Back />
      </Icon>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Icon = styled.div`
  margin-top: 60px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

GameShare.propTypes = {};

export default GameShare;
