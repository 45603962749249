export const NoOpErrorResolver = {
  resolve: (_) => {
    // no op
  },
};

export const BusinessErrorResolver = {
  resolve: ({ errorCode, message }) => {
    switch (errorCode) {
      case 103:
        return "This username is already taken.";
      case 106:
        return "This username is not allowed.";
      default:
        return message || "Something went wrong";
    }
  },
};

export const DefaultErrorResolver = {
  resolve: (error) => {
    if (error.response) {
      const { status, data } = error.response;
      switch (status) {
        case 422:
          return BusinessErrorResolver.resolve(data);
        case 500:
        case 502:
          return "Server Error";
      }
    }
    return "Something went wrong";
  },
};
