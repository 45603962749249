import api from "../api/api";

const PlaceService = {
  getPlace: async (id) => {
    const res = await api.get(`/api/v1/pubs/${id}`);
    return res.data;
  },
};

export default PlaceService;
