import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { usePlace } from "contexts/PlaceContext";
import { useLastLaswer, usePoints } from "contexts/ProgrammeContext";
import React, { useEffect } from "react";
import { useAsync } from "react-async";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import LCPQLogo from "../components/LCPQLogo";
import { useParticipant, useParticipantAlias } from "../contexts/ParticipantContext";
import { DefaultErrorResolver } from "../resolvers/error_resolvers";
import GameService from "../services/GameService";

const login = async ([username], { game, pubId }) => GameService.join(game, pubId, username);

const JoinGame = ({ game }) => {
  const [alias, setAlias] = useParticipantAlias();
  const { setParticipant } = useParticipant();
  const { updatePoints } = usePoints();
  const { updateLastAnswer } = useLastLaswer();
  const pub = usePlace();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { data, run: joinGame } = useAsync({
    deferFn: login,
    game,
    pubId: pub.id,
    onReject: (error) => setError("alias", { message: DefaultErrorResolver.resolve(error) }),
  });

  const onSubmit = ({ alias }) => joinGame(alias);

  useEffect(() => {
    if (data) {
      setAlias({ name: data.alias });
      setParticipant({ id: data.id, username: alias, game: game.gameId, gameUuid: game.uuid });
      updatePoints(null);
      updateLastAnswer(null);
    }
  }, [alias, data, game.gameId, setAlias, setParticipant, game.uuid, updatePoints, updateLastAnswer]);

  return (
    <div
      className={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
      `}
    >
      <div
        className={css`
          flex: 1;
        `}
      />
      <LCPQLogo />
      <div
        className={css`
          flex: 1;
        `}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          className={css`
            width: 100%;
            min-height: 56px;
            border: 1px solid #8175c1;
            box-sizing: border-box;
            border-radius: 100px;
            background-color: transparent;
            font-size: 18px;
            font-weight: 400;
            padding: 0px 24px;
            color: white;
            margin-bottom: 8px;
            ::placeholder {
              font-style: italic;
              color: #8175c1;
              opacity: 0.7;
            }
          `}
          placeholder="Enter Your Username"
          {...register("alias", {
            value: alias?.name,
            required: "Required",
            maxLength: { value: 20, message: "Max 20 characters" },
          })}
        />
        <ErrorMessage>{errors?.alias?.message}</ErrorMessage>
        <Button
          type="submit"
          className={css`
            width: 100%;
            font-size: 24px;
            margin-top: 8px;
          `}
        >
          Lets Play!
        </Button>
      </form>
      <span
        className={css`
          font-size: 12px;
          opacity: 0.7;
          margin: 14px 0 24px 0;
        `}
      >
        Usernames expire when you stop playing.
      </span>
    </div>
  );
};

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #d4484c;
  margin-left: 12px;
`;

export default JoinGame;
