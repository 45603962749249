import * as React from "react";

function UnhappyFace(props) {
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={120}
        height={120}
      >
        <path fill="#fff" d="M0 0h120v120H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <mask
          id="prefix__b"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={120}
          height={120}
        >
          <path fill="#fff" d="M0 0h120v120H0z" />
        </mask>
        <g filter="url(#prefix__filter0_d)" mask="url(#prefix__b)">
          <path
            d="M105.802 60.001c0 25.296-20.506 45.802-45.801 45.802-25.296 0-45.802-20.506-45.802-45.802S34.705 14.2 60 14.2c25.295 0 45.801 20.506 45.801 45.802z"
            fill="url(#prefix__paint0_radial)"
          />
          <path
            d="M105.802 60.001c0 25.296-20.506 45.802-45.801 45.802-25.296 0-45.802-20.506-45.802-45.802S34.705 14.2 60 14.2c25.295 0 45.801 20.506 45.801 45.802z"
            fill="url(#prefix__paint1_radial)"
          />
          <path
            d="M45.989 78.049a4.195 4.195 0 014.195-4.196h19.633a4.195 4.195 0 010 8.39H50.184a4.195 4.195 0 01-4.195-4.194z"
            fill="#7F2F17"
          />
          <ellipse cx={42.722} cy={50.526} rx={5.838} ry={6.604} fill="#7F2F17" />
          <ellipse cx={77.283} cy={50.526} rx={5.838} ry={6.604} fill="#7F2F17" />
        </g>
      </g>
      <defs>
        <radialGradient
          id="prefix__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-121.418 66.158 24.305) scale(84.0718 87.4905)"
        >
          <stop stopColor="#FF9D41" />
          <stop offset={1} stopColor="#FFD75C" />
        </radialGradient>
        <radialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(32.51529 53.72086 -45.8429 27.74705 43.5 36.531)"
        >
          <stop stopColor="#FFEFA5" />
          <stop offset={1} stopColor="#FFE25E" stopOpacity={0} />
        </radialGradient>
        <filter
          id="prefix__filter0_d"
          x={-5.801}
          y={-5.801}
          width={131.604}
          height={131.604}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default UnhappyFace;
