import PropTypes from "prop-types";
import { Line } from "rc-progress";
import React, { useMemo } from "react";
import { useElapsedTime } from "use-elapsed-time";
import { css } from "@emotion/css";

export default function LinearTimer({ duration, time, onComplete }) {
  const { elapsedTime } = useElapsedTime({
    isPlaying: true,
    duration,
    startAt: time,
    updateInterval: 0.2,
    onComplete,
  });

  const percent = useMemo(() => (1 - elapsedTime / duration) * 100, [duration, elapsedTime]);
  const timeLeft = useMemo(() => {
    const time = parseInt(duration - elapsedTime, 10);
    if (time < 10) {
      return "0" + time;
    }
    return time;
  }, [duration, elapsedTime]);

  return (
    <div
      className={css`
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <p
        className={css`
          position: absolute;
          color: white;
          opacity: 0.8;
          margin: 0;
        `}
      >
        {timeLeft}
      </p>
      <Line percent={percent} trailWidth={5} strokeWidth={5} strokeColor="#BA65F8" trailColor="#28264F" />
    </div>
  );
}

LinearTimer.propTypes = {
  duration: PropTypes.number.isRequired,
  time: PropTypes.number,
  onComplete: PropTypes.func,
};
