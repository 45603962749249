import { CurrentTimeProvider } from "contexts/CurrentTimeContext";
import { useParticipant } from "contexts/ParticipantContext";
import { usePlace } from "contexts/PlaceContext";
import ProgrammeController from "controllers/ProgrammeController";
import { useAsync } from "react-async";
import Home from "screen/Home";
import JoinGame from "screen/JoinGame";
import GameService from "services/GameService";
import Programme from "./screen/Programme";

const loadActiveGame = async ({ pub }) => GameService.getActiveGame(pub, null);

const PrivateApp = ({ game }) => {
  const { participant } = useParticipant();
  const pub = usePlace();

  const { data: activeGame } = useAsync({
    promiseFn: loadActiveGame,
    pub,
  });

  if (activeGame && activeGame.id == game.gameId) {
    if (participant?.gameUuid == activeGame.uuid) {
      return (
        <CurrentTimeProvider>
          <ProgrammeController />
          <Programme />
        </CurrentTimeProvider>
      );
    }
    return <JoinGame game={game} />;
  }

  return <Home />;
};

export default PrivateApp;
