import logo from "assets/Images/logo.png";
import React from "react";
import { AspectRatio } from "react-aspect-ratio";

const LCPQLogo = () => (
  <AspectRatio ratio={2.28} style={{ height: 125, width: 285 }}>
    <img src={logo} alt="logo" />
  </AspectRatio>
);

export default LCPQLogo;
