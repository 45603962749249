import React, { useCallback, useMemo, useState } from "react";
import StompClient from "../components/StompClient";
import { useCurrentTime } from "../contexts/CurrentTimeContext";
import { usePlace } from "../contexts/PlaceContext";
import { GAME_STATUS, useGame } from "../contexts/ProgrammeContext";
import useStompTopics from "../hooks/useStompTopics";
import FirebaseAnalyticsService from "../services/FirebaseAnalyticsService";
import Durations from "../utils/Durations";

const withGameTimer = (WrappedComponent) => {
  const Component = (props) => {
    const [syncedTime, setTime] = useState();
    const { game, status } = useGame();
    const { gameTopicCountdown } = useStompTopics();
    const duration = useMemo(() => game.countdownDurations * 60, [game.countdownDurations]);
    const place = usePlace();
    const getCurrentTime = useCurrentTime();
    const time = useMemo(() => {
      const diff = Durations.diff(getCurrentTime(), game.startDate);
      if (diff < 0) {
        return Math.abs(diff);
      }
      return 0;
    }, [game.startDate, getCurrentTime]);

    const handleOnMessage = useCallback(
      ({ remaining, time }) => {
        FirebaseAnalyticsService.logGameCountDownLatency(getCurrentTime(), time, place.id);
        setTime(remaining);
      },
      [place, getCurrentTime]
    );

    return (
      <>
        <WrappedComponent {...props} duration={duration} time={syncedTime || time} />
        {status === GAME_STATUS.started && (
          <StompClient debug topics={[gameTopicCountdown]} onMessage={handleOnMessage} />
        )}
      </>
    );
  };

  Component.displayName = `withGameTimer(${WrappedComponent.displayName || WrappedComponent.name})`;

  return Component;
};

export default withGameTimer;
