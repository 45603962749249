import { css } from "@emotion/css";
import UnhappyFace from "components/UnhappyFace";
import React from "react";
import GameAnswer from "screen/Game/components/GameAnswer";
import { PointsGain, TotalPoints } from "./components";

const IncorrectAnswer = ({ totalPoints, answer, rightAnswer }) => (
  <>
    <PointsGain>{`0 Points`}</PointsGain>
    <TotalPoints>{`${totalPoints || 0} total`}</TotalPoints>
    <div
      className={css`
        padding: 32px 0px;
      `}
    >
      <UnhappyFace />
    </div>
    {answer ? (
      <span
        className={css`
          font-weight: 700;
          font-size: 28px;
          color: #d4484c;
          text-decoration: line-through;
          margin-bottom: 26px;
        `}
      >
        {answer}
      </span>
    ) : (
      <span
        className={css`
          font-weight: bold;
          font-size: 24px;
          line-height: 133.4%;
          color: #d14950;
          margin-bottom: 26px;
        `}
      >
        {answer || "Time is up!"}
      </span>
    )}

    <span
      className={css`
        align-self: flex-start;
        font-weight: normal;
        font-size: 12px;
        line-height: 166%;
        color: #8175c1;
        opacity: 0.8;
        padding: 4px 24px;
      `}
    >
      Correct answer is
    </span>
    <GameAnswer isRight text={rightAnswer} />
  </>
);

IncorrectAnswer.propTypes = {};

export default IncorrectAnswer;
