import api from "../api/api";

const GameService = {
  getActiveGame: async (pub, participantId) => {
    const activeGame = await api.get(`/api/v1/games/active${pub.isDemo ? "-demo" : ""}`, {
      headers: { shuffle: true },
    });
    const qrcode = await api.get(`/api/v1/games/${activeGame.data.id}/generate/qr-code/pub/${pub.id}`);
    const game = activeGame.data;
    const { status } = game;

    let leaderboard;
    if ((status === "IN_PROGRESS" || status === "LEADERBOARD") && participantId) {
      leaderboard = await GameService.getLeaderboard(game.id, pub.id, participantId);
    }

    return { ...game, qrcode: qrcode.data, leaderboard };
  },
  getLeaderboard: async (gameId, pubId, participantId) => {
    const res = await api.get(`/api/v1/games/${gameId}/leaderboard/pub/${pubId}?participantId=${participantId}`);
    return res.data;
  },
  join: async (game, pubId, username) => {
    const res = await api.post(`/api/v1/games/${game.gameId}/join/pub/${pubId}?username=${username}&uuid=${game.uuid}`);
    return res.data;
  },
};

export default GameService;
