import { css } from "@emotion/css";
import PropTypes from "prop-types";
import React from "react";
import { AspectRatio } from "react-aspect-ratio";
import logo from "../../assets/Images/logo.png";
import Numbers from "../../utils/Numbers";

const MyBoard = ({ rank, onShowAll }) => (
  <div
    className={css`
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}
  >
    <AspectRatio ratio={2.28} style={{ height: 125, width: 285 }}>
      <img src={logo} alt="logo" />
    </AspectRatio>
    <span
      className={css`
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #ffffff;
        margin-top: 56px;
      `}
    >
      Your rank is
    </span>
    <span
      className={css`
        font-weight: bold;
        font-size: 36px;
        line-height: 166%;
        text-align: center;
        letter-spacing: 0.4px;
        color: #ffffff;
        background: #28264f;
        border-radius: 100px;
        width: 80%;
        margin: 16px 24px;
      `}
    >
      {Numbers.ordinalSuffixOf(rank)}
    </span>
    <button
      className={css`
        width: 80%;
        line-height: 166%;
        letter-spacing: 0.4px;
        border: 1px solid #8175c1;
        box-sizing: border-box;
        border-radius: 100px;
        background: linear-gradient(91.77deg, #6cecfd 3.48%, #5849a6 65.7%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        padding: 8px;
        margin-top: 8px;
        cursor: pointer;
      `}
      onClick={onShowAll}
    >
      Leaderboard
    </button>
  </div>
);

MyBoard.propTypes = {
  rank: PropTypes.number.isRequired,
  onShowAll: PropTypes.func.isRequired,
};

export default MyBoard;
