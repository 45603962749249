import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function CircleTimer({ duration, time, clockSize, onComplete, renderInfo }) {
  return (
    <div>
      <CountdownCircleTimer
        isPlaying
        duration={duration}
        initialRemainingTime={time}
        colors={[
          ["#BA65F8", 0.5],
          ["#FA4B6A", 0.5],
        ]}
        trailColor="transparent"
        strokeWidth={10}
        strokeLinecap="round"
        size={clockSize}
        onComplete={onComplete}
      >
        {renderInfo}
      </CountdownCircleTimer>
    </div>
  );
}
