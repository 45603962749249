import styled from "@emotion/styled";

export const PointsGain = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 133.4%;
  text-align: center;
  color: #ffffff;
`;

export const TotalPoints = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #8175c1;
`;
