import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import Logo from "../../components/Logo";
import { usePoints, useQuestion } from "../../contexts/ProgrammeContext";
import CorrectAnswer from "./CorrectAnswer";
import IncorrectAnswer from "./IncorrectAnswer";

const TopRightLogo = () => (
  <div
    className={css`
      position: absolute;
      @media (min-width: 320px) {
        top: 16px;
        right: 16px;
      }
      @media (min-width: 576px) {
        top: 24px;
        right: 24px;
      }
    `}
  >
    <Logo
      className={css`
        @media (min-width: 320px) {
          width: 35px;
          height: 41px;
        }
        @media (min-width: 576px) {
          width: 70px;
          height: 92px;
        }
      `}
    />
  </div>
);

const AnswerReveal = () => {
  const { lastAnswer, question } = useQuestion();
  const { points } = usePoints();
  const rightAnswer = useMemo(() => question.answers.find((it) => it.isRight), [question.answers]).answer;

  if (points) {
    const { point, totalPoint } = points;

    return (
      <Container>
        <TopRightLogo />
        {point > 0 ? (
          <CorrectAnswer pointsGain={point} totalPoints={totalPoint} answer={rightAnswer} />
        ) : (
          <IncorrectAnswer pointsGain={point} totalPoints={totalPoint} answer={lastAnswer} rightAnswer={rightAnswer} />
        )}
      </Container>
    );
  }

  return (
    <Container>
      <TopRightLogo />
      <IncorrectAnswer totalPoints={0} rightAnswer={rightAnswer} />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default AnswerReveal;
