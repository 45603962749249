import { useMemo } from "react";
import { useGame } from "../contexts/ProgrammeContext";
import { usePlace } from "../contexts/PlaceContext";

const useStompTopics = () => {
  const place = usePlace();
  const { game } = useGame();

  return useMemo(() => {
    const isDemo = Boolean(place?.isDemo);
    const gameStarted = Boolean(game?.id);

    const topics = isDemo
      ? { gameTopic: "/topic/game-demo", gameTopicCountdownStart: "/topic/game-start-countdown-demo" }
      : { gameTopic: "/topic/game", gameTopicCountdownStart: "/topic/game-start-countdown" };

    return gameStarted
      ? isDemo
        ? {
            ...topics,
            gameTopicCountdown: `/topic/game-${game.id}-countdown-demo`,
            gameQuestionTopic: `/topic/game-${game.id}-question-demo`,
          }
        : {
            ...topics,
            gameTopicCountdown: `/topic/game-${game.id}-countdown`,
            gameQuestionTopic: `/topic/game-${game.id}-question`,
          }
      : topics;
  }, [place?.isDemo, game?.id]);
};

export default useStompTopics;
