import StompClient from "components/StompClient";
import { useParticipant } from "contexts/ParticipantContext";
import { useGame, useLeaderboard, useQuestion } from "contexts/ProgrammeContext";
import { useCallback, useEffect, useMemo } from "react";
import { useAsync } from "react-async";
import { useHistory } from "react-router-dom";
import { usePlace } from "../contexts/PlaceContext";
import { GAME_STATUS, LEADERBOARD_STATUS } from "../contexts/ProgrammeContext";
import GameService from "../services/GameService";
import FirebaseAnalyticsService from "services/FirebaseAnalyticsService";
import { useCurrentTime } from "contexts/CurrentTimeContext";
import useStompTopics from "../hooks/useStompTopics";

const syncGame = ([], { pub, participantId }) => GameService.getActiveGame(pub, participantId);
const getGame = ({ pub, participantId }) => GameService.getActiveGame(pub, participantId);
const getLeaderboard = ([], { gameId, pubId, participantId }) =>
  GameService.getLeaderboard(gameId, pubId, participantId);

const ProgrammeController = () => {
  const place = usePlace();
  const { gameTopic, gameTopicCountdownStart, gameQuestionTopic } = useStompTopics();
  const { push } = useHistory();
  const { participant, setParticipant } = useParticipant();
  const { status: gameStatus, start: startGame, finish: finishGame, resume: resumeGame, resetGame } = useGame();
  const { start: startQuestion, finish: finishQuestion, reveal } = useQuestion();
  const {
    leaderboard: initialLeaderboard,
    status: leaderboardStatus,
    start: startLeaderboard,
    finish: finishLeaderboard,
  } = useLeaderboard();

  const {
    data: game,
    run: runGetGame,
    setData: setGame,
  } = useAsync({
    promiseFn: getGame,
    deferFn: syncGame,
    pub: place,
    participantId: participant?.id,
  });

  const getCurrentTime = useCurrentTime();

  const {
    data: leaderboard,
    run: runGetLeaderboard,
    setData: setLeaderboard,
  } = useAsync({
    initialValue: initialLeaderboard,
    deferFn: getLeaderboard,
    gameId: game?.id,
    pubId: place?.id,
    participantId: participant.id,
  });

  const handleGame = useCallback(
    ({ status }) => {
      switch (status) {
        case "COUNTING_DOWN":
          runGetGame(place.id);
          break;
        case "LEADERBOARD":
          finishGame();
          startLeaderboard(leaderboard);
          break;
        case "ENDED":
          finishLeaderboard();
          break;
      }
    },
    [runGetGame, place.id, finishGame, startLeaderboard, leaderboard, finishLeaderboard]
  );

  const handleQuestion = useCallback(
    ({ id, state }) => {
      switch (state) {
        case "QUESTION":
          startQuestion(id);
          break;
        case "ANSWER":
          reveal();
          runGetLeaderboard(); // pre-fetch leaderboard
          break;
        case "LEADERBOARD":
          startLeaderboard(leaderboard);
          break;
        case "END":
          finishQuestion();
          break;
      }
    },
    [startQuestion, reveal, runGetLeaderboard, startLeaderboard, leaderboard, finishQuestion]
  );

  const handleMessage = useCallback(
    (msg, destination) => {
      if (destination === gameTopic || destination === gameTopicCountdownStart) {
        FirebaseAnalyticsService.logGameLatency(getCurrentTime(), msg.time, place.id);
        handleGame(msg);
      } else if (destination === gameQuestionTopic) {
        FirebaseAnalyticsService.logQuestionLatency(getCurrentTime(), msg.time, place.id);
        handleQuestion(msg);
      }
    },
    [gameTopic, gameTopicCountdownStart, gameQuestionTopic, getCurrentTime, place.id, handleGame, handleQuestion]
  );

  useEffect(() => {
    if (gameStatus === GAME_STATUS.idle && game) {
      const { status } = game;
      if (status === "IN_PROGRESS" || status === "LEADERBOARD") {
        resumeGame(game);
      } else if (status === "COUNTING_DOWN") {
        startGame(game);
      }
    }
  }, [game, gameStatus, place.id, resumeGame, runGetGame, startGame]);

  // clean up
  useEffect(() => {
    if (gameStatus === GAME_STATUS.finished) {
      setGame(null);
    }
    if (leaderboardStatus === LEADERBOARD_STATUS.finished) {
      setLeaderboard(null);
      resetGame();
      setParticipant(null);
      push("/");
    }
  }, [gameStatus, leaderboardStatus, setGame, setLeaderboard, resetGame, setParticipant, push]);

  const topics = useMemo(() => {
    const base = [gameTopicCountdownStart, gameTopic];
    if (game) {
      return [...base, gameQuestionTopic];
    }
    return base;
  }, [game, gameQuestionTopic, gameTopic, gameTopicCountdownStart]);

  return <StompClient topics={topics} onMessage={handleMessage} />;
};

export default ProgrammeController;
