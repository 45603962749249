import styled from "@emotion/styled";
import backgroundImageLarge from "assets/background/background-large.png";
import backgroundImage from "assets/background/background.png";
import PropTypes from "prop-types";
import React from "react";

const Section = ({ children }) => (
  <FullScreen>
    <Container>{children}</Container>
  </FullScreen>
);

Section.propTypes = {
  children: PropTypes.any.isRequired,
};

const FullScreen = styled.section`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImageLarge});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    background-image: url(${backgroundImage});
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 24px;
`;

export default Section;
