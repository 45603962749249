import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Logo from "components/Logo";
import { useCurrentTime } from "contexts/CurrentTimeContext";
import { GridColumn, GridRow } from "emotion-flex-grid";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useAsync } from "react-async";
import LinearTimer from "../../components/Timer/LinearTimer";
import { useParticipant } from "../../contexts/ParticipantContext";
import { useGame, usePoints, useQuestion } from "../../contexts/ProgrammeContext";
import QuizService from "../../services/QuizService";
import Durations from "../../utils/Durations";
import GameAnswer from "./components/GameAnswer";
import GameProgressIndicator from "./components/GameProgressIndicator";
import GameQuestionHeader from "./components/GameQuestionHeader";

const answerQuiz = ([answer], { participant, quiz }) => QuizService.submitAnswer(participant.id, quiz, answer);

const GameQuestion = () => {
  const { game } = useGame();
  const { participant } = useParticipant();
  const { question: data, current, total, answer, lastAnswer } = useQuestion();
  const { startDate, answers, question } = data;
  const { updatePoints } = usePoints();
  const getCurrentTime = useCurrentTime();
  const time = useMemo(() => Math.abs(Durations.diff(getCurrentTime(), startDate)), [startDate, getCurrentTime]);

  const { run: submitAnswer } = useAsync({
    deferFn: answerQuiz,
    participant,
    quiz: data.id,
    onResolve: (data) => updatePoints(data),
  });

  const [selection, _setSelection] = useState();
  const setSelection = useCallback(
    (index) => {
      _setSelection(index);
      submitAnswer(data.answers[index].answer);
      answer(data.answers[index].answer);
    },
    [data.answers, submitAnswer, answer]
  );

  useEffect(() => {
    if (lastAnswer) {
      const index = data.answers.findIndex((it) => it.answer === lastAnswer);
      if (index >= 0) {
        _setSelection(index);
      }
    }
  }, [lastAnswer, data.answers]);

  return (
    <Container>
      <GameQuestionHeader>
        <div
          className={css`
            position: relative;
            display: flex;
          `}
        >
          <LinearTimer duration={game.questionDurations} time={time} />
          <div
            className={css`
              position: absolute;
              @media (min-width: 320px) {
                top: -8px;
                right: 8px;
              }
              @media (min-width: 576px) {
                top: -18px;
                right: 16px;
              }
            `}
          >
            <Logo
              className={css`
                @media (min-width: 320px) {
                  width: 35px;
                  height: 41px;
                }
                @media (min-width: 576px) {
                  width: 70px;
                  height: 92px;
                }
              `}
            />
          </div>
        </div>
        <GameProgressIndicator current={current} total={total} />
      </GameQuestionHeader>
      <QuestionContainer>
        <Question>{question}</Question>
      </QuestionContainer>
      <GridRow wrap="wrap" align="center" justify="between">
        {answers.map(({ answer }, index) => (
          <GridColumn key={index} width="100%" mb={["m"]} onClick={() => setSelection(index)}>
            <GameAnswer text={answer} isSelected={selection === index} />
          </GridColumn>
        ))}
      </GridRow>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const Question = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 133.4%;
  text-align: center;
  color: #ffffff;
`;

export default GameQuestion;
