import { initFirebase } from "firebase";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { PlaceProvider } from "./contexts/PlaceContext";
import PrivateApp from "./PrivateApp";
import Home from "./screen/Home";

export default function App() {
  const matched = useRouteMatch("/join/:uuid/game/:gameId/pub/:pubId");

  useEffect(() => {
    initFirebase();
  }, []);

  if (matched) {
    const {
      params: { uuid, gameId, pubId },
    } = matched;

    return (
      <PlaceProvider pubId={pubId}>
        <PrivateApp game={{ uuid, gameId }} />
      </PlaceProvider>
    );
  }

  return <Home />;
}
