import api from "../api/api";

const QuizService = {
  submitAnswer: async (participant, quiz, answer) => {
    const res = await api.post(`/api/v1/games/submit/quiz/${quiz}/participant/${participant}`, { answer });
    return res.data;
  },
};

export default QuizService;
