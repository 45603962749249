import React from "react";
import { GAME_STATUS, QUESTION_STATUS, useProgramme } from "../../contexts/ProgrammeContext";
import AnswerReveal from "../Answer/AnswerReveal";
import GameLeaderboard from "../Leaderboard/GameLeaderboard";
import GameCountDown from "./GameCountDown";
import GameQuestion from "./GameQuestion";

const Game = () => {
  const { gameStatus, questionStatus } = useProgramme();

  if (gameStatus === GAME_STATUS.started) {
    return <GameCountDown />;
  } else if (questionStatus === QUESTION_STATUS.displaying) {
    return <GameQuestion />;
  } else if (questionStatus === QUESTION_STATUS.revealed) {
    return <AnswerReveal />;
  } else {
    return <GameLeaderboard />;
  }
};

export default Game;
