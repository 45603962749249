import React from "react";

export default function CrownIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline", margin: "auto" }}
    >
      <g filter="url(#filter0_d)">
        <g filter="url(#filter1_di)">
          <path
            d="M11.0987 17.1553L9.52663 13.7228C9.43046 13.5128 9.45827 13.2516 9.59527 13.078L11.1673 11.0855C11.346 10.859 11.6448 10.859 11.8235 11.0855L13.3955 13.078C13.5325 13.2516 13.5603 13.5128 13.4642 13.7228L11.8921 17.1553C11.7195 17.5323 11.2713 17.5323 11.0987 17.1553Z"
            fill="#FFF8DE"
          />
        </g>
        <path
          d="M5.4126 16.8777C5.4126 16.589 5.65378 16.3549 5.95129 16.3549H17.0396C17.3371 16.3549 17.5783 16.589 17.5783 16.8777V18.9662C17.5783 19.255 17.3371 19.4891 17.0396 19.4891H5.95129C5.65378 19.4891 5.4126 19.255 5.4126 18.9662V16.8777Z"
          fill="#FF7A00"
        />
        <path
          d="M8.15171 8.44413L10.5702 3.57686C10.9524 2.80771 12.0385 2.80771 12.4206 3.57686L14.8392 8.44413C15.128 9.02532 15.8656 9.20235 16.382 8.81439L18.3374 7.34523C19.0661 6.79769 20.0894 7.39612 19.9832 8.30776L18.9102 17.522C18.8488 18.0491 18.4069 18.4463 17.8819 18.4463H5.10901C4.58402 18.4463 4.14212 18.0491 4.08074 17.522L3.00764 8.30776C2.90147 7.39612 3.92475 6.79769 4.65351 7.34523L6.60892 8.81439C7.12528 9.20235 7.86292 9.02532 8.15171 8.44413Z"
          fill="url(#paint0_radial)"
        />
        <path
          d="M10.6823 10.5118C11.0969 9.98011 11.8941 9.98011 12.3086 10.5118L13.9131 12.5699C14.2094 12.9499 14.2094 13.4857 13.9131 13.8657L12.3086 15.9238C11.8941 16.4555 11.0969 16.4555 10.6823 15.9238L9.07781 13.8657C8.78152 13.4857 8.78152 12.9499 9.07781 12.5699L10.6823 10.5118Z"
          fill="url(#paint1_radial)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-1"
          y="-1"
          width="24.9909"
          height="24.4891"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_di"
          x="5.46979"
          y="7.91565"
          width="12.0512"
          height="14.5224"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.68 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.914286 0 0 0 0 0.55 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.03319 9.12624) rotate(56.9028) scale(11.1252 9.90096)"
        >
          <stop stopColor="#FFEB81" />
          <stop offset="0.682292" stopColor="#FFC839" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4366 11.9969) rotate(65.4804) scale(4.18243 4.45439)"
        >
          <stop stopColor="#FF7D16" />
          <stop offset="1" stopColor="#FF3E12" />
        </radialGradient>
      </defs>
    </svg>
  );
}
