import styled from "@emotion/styled";

const GameQuestionHeader = styled.div`
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #8175c180;
  color: #8175c1;
  letter-spacing: 0.15px;
  position: relative;
`;

export default GameQuestionHeader;
