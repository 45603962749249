import React from "react";
import { GAME_STATUS, LEADERBOARD_STATUS, useGame, useLeaderboard } from "../contexts/ProgrammeContext";
import useKeepAwake from "../hooks/useKeepAwake";
import Game from "./Game/Game";
import FinalLeaderboard from "./Leaderboard/FinalLeaderboard";
import useCleanUp from "../hooks/useCleanUp";

const Programme = () => {
  const { game, status: gameStatus } = useGame();
  const { status: leaderboardStatus, leaderboard } = useLeaderboard();
  const sleep = useKeepAwake();

  useCleanUp(() => sleep());

  if (game && gameStatus !== GAME_STATUS.finished) {
    return <Game />;
  }
  if (leaderboard && leaderboardStatus === LEADERBOARD_STATUS.displaying) {
    return <FinalLeaderboard leaderboard={leaderboard} />;
  }
  return <></>;
};

export default Programme;
