import { ThemeProvider } from "@emotion/react";
import Section from "components/Shared/Section";
import { ParticipantProvider } from "contexts/ParticipantContext";
import { ProgrammeProvider } from "contexts/ProgrammeContext";
import React from "react";
import "react-aspect-ratio/aspect-ratio.css";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import theme from "theme";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Section>
        <Router>
          <Switch>
            <ParticipantProvider>
              <ProgrammeProvider>
                <App />
              </ProgrammeProvider>
            </ParticipantProvider>
          </Switch>
        </Router>
      </Section>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
