import styled from "@emotion/styled";
import Logo from "../../components/Logo";
import { css } from "@emotion/css";

const LeaderboardTitle = () => (
  <div
    className={css`
      position: relative;
      display: flex;
    `}
  >
    <Title>Leaderboard</Title>
    <div
      className={css`
        position: absolute;
        @media (min-width: 320px) {
          right: 16px;
          top: -8px;
        }
        @media (min-width: 576px) {
          top: -16px;
          right: 16px;
        }
      `}
    >
      <Logo
        className={css`
          @media (min-width: 320px) {
            width: 54px;
            height: 64px;
          }
          @media (min-width: 576px) {
            width: 70px;
            height: 92px;
          }
        `}
      />
    </div>
  </div>
);

const Title = styled.div`
  width: 100%;
  background: #28264f;
  border-radius: 100px;
  text-align: center;
  padding: 12px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
`;

export default LeaderboardTitle;
