import { css } from "@emotion/css";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Animated } from "react-animated-css";
import CircleTimer from "../../components/Timer/CircleTimer";
import { useGame } from "../../contexts/ProgrammeContext";
import withGameTimer from "../../handlers/withGameTimer";
import GameSoon from "./components/GameSoon";
import GameTimer from "./components/GameTimer";
import GameShare from "./GameShare";

const GameCountDown = ({ duration, time }) => {
  const [shareQRCode, _setShareQRCode] = useState(false);
  const { game } = useGame();

  const renderTimerInfo = useCallback(({ remainingTime }) => {
    if (remainingTime > 0) {
      return <GameTimer seconds={remainingTime} />;
    } else {
      return <GameSoon />;
    }
  }, []);

  const showShareQRCode = useCallback(() => _setShareQRCode(true), []);
  const hideShareQRCode = useCallback(() => _setShareQRCode(false), []);

  return (
    <>
      {shareQRCode && (
        <Animated
          isVisible={showShareQRCode}
          animationIn="fadeIn"
          animationOut="fadeOut"
          className={css`
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <GameShare onBack={hideShareQRCode} />
        </Animated>
      )}
      <Container visible={!shareQRCode}>
        <CircleTimer duration={duration} time={time} clockSize={250} strokeWidth={10} renderInfo={renderTimerInfo} />
        <QRCode onClick={showShareQRCode}>
          <img src={game?.qrcode?.qrCode} alt="qr-code" width="72px" height="72px" />
        </QRCode>
        <div
          className={css`
            margin-top: 16px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            opacity: 0.7;
          `}
        >
          Invite your friend
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const QRCode = styled.div`
  margin-top: 90px;
  text-align: center;
  background: transparent;
  border: none;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  width: 72px;
  height: 72px;
`;

GameCountDown.propTypes = {
  duration: PropTypes.number,
  time: PropTypes.number,
};

export default withGameTimer(GameCountDown);
