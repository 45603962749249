import PropTypes from "prop-types";
import React from "react";

const right = {
  background: "linear-gradient(89.66deg, #6CECFD 0.17%, #2E9A93 47.68%, #108C6F 88.96%)",
  border: "none",
};

const unselected = {
  backgroundColor: "transparent",
  border: "0.2vw solid #8175c1",
};

const selected = {
  background: "linear-gradient(91.77deg, #8175C1 3.48%, #7C70BD 37.11%, #5849A6 65.7%)",
  border: "none",
};

const base = {
  width: "100%",
  minHeight: "56px",
  borderRadius: "100px",
  boxSizing: "border-box",
  fontSize: "24px",
  fontWeight: 700,
  color: "#ffffff",
};

const GameAnswer = ({ text, isRight, isSelected }) => (
  <button style={{ ...base, ...(isRight ? right : isSelected ? selected : unselected) }}>{text}</button>
);

GameAnswer.propTypes = {
  isRight: false,
  isSelected: false,
};

GameAnswer.propTypes = {
  text: PropTypes.string.isRequired,
  isRight: PropTypes.bool,
};

export default GameAnswer;
