import { useCallback, useEffect } from "react";
import NoSleep from "nosleep.js";

const noSleep = new NoSleep();

const useKeepAwake = () => {
  const stop = useCallback(() => noSleep.disable(), []);

  useEffect(() => {
    window.addEventListener(
      "click",
      function enableNoSleep() {
        window.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false
    );
  }, []);

  return stop;
};

export default useKeepAwake;
