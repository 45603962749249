/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import TimeService from "../services/TimeService";
import { useAsync } from "react-async";

const getTimeOffset = () => TimeService.getTimeOffset();

const CurrentTimeContext = React.createContext();

export const useCurrentTime = () => {
  const { getCurrentTime } = useContext(CurrentTimeContext);
  return getCurrentTime;
};

export const CurrentTimeProvider = ({ children }) => {
  const { data: offset, run: syncOffset } = useAsync({ promiseFn: getTimeOffset, deferFn: getTimeOffset });
  useEffect(() => {
    const syncOffsetInterval = setInterval(() => {
      syncOffset();
    }, 1000 * 60 * 5);
    return () => clearInterval(syncOffsetInterval);
  }, []);

  const getCurrentTime = useCallback(() => {
    return new Date(Date.now() + offset);
  }, [offset]);

  return <CurrentTimeContext.Provider value={{ getCurrentTime, offset }}>{children}</CurrentTimeContext.Provider>;
};

CurrentTimeProvider.propTypes = {
  children: PropTypes.node,
};
