import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import MyBoard from "./MyBoard";
import FullLeaderboard from "./FullLeaderboard";
import { Animated } from "react-animated-css";
import { css } from "@emotion/css";

const FinalLeaderboard = ({ leaderboard }) => {
  const [showAll, _setShowAll] = useState(false);

  const setShowAll = useCallback(() => _setShowAll(true), []);
  const setShowMine = useCallback(() => _setShowAll(false), []);

  if (showAll) {
    return (
      <Animated
        isVisible={showAll}
        animationIn="fadeIn"
        animationOut="fadeOut"
        className={css`
          flex: 1;
        `}
      >
        <FullLeaderboard leaderboard={leaderboard} onBack={setShowMine} />
      </Animated>
    );
  }

  return <MyBoard rank={leaderboard.myResult?.rank} onShowAll={setShowAll} />;
};

FinalLeaderboard.propTypes = {
  data: PropTypes.array,
};

export default FinalLeaderboard;
