import useLocalStorage from "hooks/useLocalStorage";
import React, { useContext } from "react";

const KEY_PARTICIPANT = "KEY.PARTICIPANT";
const KEY_PARTICIPANT_ALIAS = "KEY.PARTICIPANT_ALIAS";

const ParticipantContext = React.createContext();

export const useParticipant = () => useContext(ParticipantContext);
export const useParticipantAlias = () => useLocalStorage(KEY_PARTICIPANT_ALIAS);

export const ParticipantProvider = ({ children }) => {
  const [participant, setParticipant] = useLocalStorage(KEY_PARTICIPANT);

  return <ParticipantContext.Provider value={{ participant, setParticipant }}>{children}</ParticipantContext.Provider>;
};
