import { css } from "@emotion/css";
import React from "react";
import SmileFace from "../../components/SmileFace";
import GameAnswer from "../Game/components/GameAnswer";
import { PointsGain, TotalPoints } from "./components";

const CorrectAnswer = ({ pointsGain, totalPoints, answer }) => (
  <>
    <PointsGain>{`+${pointsGain} Points`}</PointsGain>
    <TotalPoints>{`${totalPoints} total`}</TotalPoints>
    <div
      className={css`
        padding: 32px 0px;
      `}
    >
      <SmileFace />
    </div>
    <GameAnswer isRight text={answer} />
  </>
);

CorrectAnswer.propTypes = {};

export default CorrectAnswer;
