import dayjs from "dayjs";

const Durations = {
  format: (duration) => {
    let minutes = Math.floor(duration / 60);
    let seconds = duration - minutes * 60;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  },
  diff: (currentDate, date) => dayjs(currentDate).diff(dayjs(date), "second"),
};

export default Durations;
