import { css } from "@emotion/css";
import React from "react";
import LCPQLogo from "../components/LCPQLogo";

const Home = () => (
  <div
    className={css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <LCPQLogo />
  </div>
);

export default Home;
