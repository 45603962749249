import { css } from "@emotion/css";
import styled from "@emotion/styled";
import CrownIcon from "assets/Icons/CrownIcon";
import { GridColumn, GridRow } from "emotion-flex-grid";
import React from "react";

const Leaderboard = ({ leaderboard }) => (
  <>
    <GridRow wrap="wrap">
      <GridColumn width={2}>
        <TableHead textAlign="center">Rank</TableHead>
      </GridColumn>
      <GridColumn width={2} />
      <GridColumn width={5}>
        <TableHead>Username</TableHead>
      </GridColumn>
      <GridColumn width={3}>
        <TableHead textAlign="center">Points</TableHead>
      </GridColumn>
    </GridRow>
    {leaderboard?.participants?.map(({ id, rank, username, totalPoints }, index) => (
      <GridRow
        key={index}
        wrap="wrap"
        className={
          id === leaderboard?.myResult?.id
            ? css`
                background: #28264f80;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                border-radius: 40px;
                padding: 3px 0px;
              `
            : css`
                padding: 3px 0px;
              `
        }
      >
        <GridColumn width={2}>
          <Text textAlign="center">{rank}</Text>
        </GridColumn>
        <GridColumn width={2} textAlign="right">
          <div
            className={css`
              margin-right: 8px;
              margin-top: 4px;
            `}
          >
            {rank === 1 ? <CrownIcon /> : <div></div>}
          </div>
        </GridColumn>
        <GridColumn width={5}>
          <Text>{username}</Text>
        </GridColumn>
        <GridColumn width={3}>
          <Text textAlign="center">{totalPoints}</Text>
        </GridColumn>
      </GridRow>
    ))}
  </>
);

const TableHead = styled.div`
  padding-top: 8px;
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  color: #8175c1;
  opacity: 0.64;
  text-align: ${({ textAlign }) => textAlign || "start"};
`;

const Text = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  text-align: ${({ textAlign }) => textAlign || "start"};
`;

export default Leaderboard;
