import axios from "axios";
import { logIntercepter, noLogIntercepter } from "./intercepters";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Api-Key"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(logIntercepter);
axios.interceptors.response.use(noLogIntercepter, (error) => {
  const status = error?.request?.status;
  if (status && (status === 401 || status === 403)) {
    // TODO: handle authentications
  }
  throw error;
});

export default axios;
