import * as React from "react";

function Back(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.667 16.366h20M13.733 24.398l-8.067-8.032 8.067-8.033" stroke="#8175C1" />
    </svg>
  );
}

export default Back;
