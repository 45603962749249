const Arrays = {
  findLast: (array, predicate) => {
    for (let i = 0; i < array.length; i++) {
      const ele = array[array.length - 1 - i];
      if (predicate(ele)) {
        return ele;
      }
    }
  },
  chunk: (array, size = 1) => {
    const res = [];
    while (array.length > 0) {
      const chunk = array.splice(0, size);
      res.push(chunk);
    }
    return res;
  },
};

export default Arrays;
