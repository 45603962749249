const theme = {
  color: {
    main: "red",
  },
  background: {
    main: "blue",
  },
};

export default theme;
