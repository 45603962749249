import { css } from "@emotion/css";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import Back from "../../components/Back";
import Leaderboard from "../../components/Shared/Leaderboard";
import GameQuestionHeader from "../Game/components/GameQuestionHeader";
import LeaderboardTitle from "./LeaderboardTitle";

const FullLeaderboard = ({ leaderboard, onBack }) => (
  <Container>
    <GameQuestionHeader>
      <div
        className={css`
          z-index: 1;
          position: absolute;
          left: 16px;
          top: 8px;
          cursor: pointer;
        `}
        onClick={onBack}
      >
        <Back />
      </div>
      <LeaderboardTitle>Leaderboard</LeaderboardTitle>
    </GameQuestionHeader>
    <div
      className={css`
        flex: 1;
      `}
    >
      <Leaderboard leaderboard={leaderboard} />
    </div>
  </Container>
);

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

FullLeaderboard.defaultProps = {
  onBack: PropTypes.func.isRequired,
};

export default FullLeaderboard;
