const debug = require("debug")("axios");

export async function noLogIntercepter(value) {
  return value;
}

export async function logIntercepter(value) {
  debug(value);
  return value;
}
