import React from "react";
import styled from "@emotion/styled";

export default function Button({ children, ...props }) {
  return <StyledButton {...props}>{children}</StyledButton>;
}

const StyledButton = styled.button`
  height: 56px;
  border: 1px solid #8175c1;
  box-sizing: border-box;
  border-radius: 100px;
  background: linear-gradient(91.77deg, #6cecfd 3.48%, #5849a6 65.7%);
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  color: white;
`;
