import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useAsync } from "react-async";
import PlaceService from "../services/PlaceService";

export const PlaceContext = React.createContext();

export const usePlace = () => {
  const { place } = useContext(PlaceContext);
  return place;
};

const promiseFn = ({ pubId }) => PlaceService.getPlace(pubId);

export const PlaceProvider = ({ children, pubId }) => {
  const {
    data: place,
    isLoading,
    isRejected,
  } = useAsync({
    promiseFn,
    pubId,
  });

  return isLoading || isRejected ? null : <PlaceContext.Provider value={{ place }}>{children}</PlaceContext.Provider>;
};

PlaceProvider.propTypes = {
  children: PropTypes.node,
};
