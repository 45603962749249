import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={120}
        height={120}
      >
        <path fill="#fff" d="M0 0h120v120H0z" />
      </mask>
      <g filter="url(#prefix__filter0_d)" mask="url(#prefix__a)">
        <path
          d="M105.803 60c0 25.296-20.507 45.802-45.802 45.802-25.296 0-45.802-20.506-45.802-45.801 0-25.296 20.506-45.802 45.802-45.802 25.295 0 45.802 20.506 45.802 45.802z"
          fill="url(#prefix__paint0_radial)"
        />
        <path
          d="M105.803 60c0 25.296-20.507 45.802-45.802 45.802-25.296 0-45.802-20.506-45.802-45.801 0-25.296 20.506-45.802 45.802-45.802 25.295 0 45.802 20.506 45.802 45.802z"
          fill="url(#prefix__paint1_radial)"
        />
        <ellipse cx={42.723} cy={38.047} rx={5.838} ry={6.604} fill="#7F2F17" />
        <ellipse cx={77.283} cy={38.047} rx={5.838} ry={6.604} fill="#7F2F17" />
        <path
          d="M79.858 55.313c2.761 0 5.047 2.261 4.477 4.963-2.23 10.571-11.417 19.893-24.334 19.893-13.184 0-22.483-7.77-24.463-19.875-.446-2.725 1.845-4.981 4.606-4.981h39.714z"
          fill="url(#prefix__paint2_linear)"
        />
      </g>
      <defs>
        <radialGradient
          id="prefix__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-121.418 66.158 24.305) scale(84.0718 87.4905)"
        >
          <stop stopColor="#FF9D41" />
          <stop offset={1} stopColor="#FFD75C" />
        </radialGradient>
        <radialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(32.51529 53.72086 -45.8429 27.74705 43.5 36.53)"
        >
          <stop stopColor="#FFEFA5" />
          <stop offset={1} stopColor="#FFE25E" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={61.54}
          y1={55.581}
          x2={61.54}
          y2={79.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3E12" />
          <stop offset={1} stopColor="#FF7D16" stopOpacity={0.9} />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={-5.801}
          y={-5.801}
          width={131.604}
          height={131.604}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgComponent;
