import { initializeApp } from "firebase/app";
import "firebase/analytics";
import { getAnalytics } from "firebase/analytics";

export function initFirebase() {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
  getAnalytics(app);
}
