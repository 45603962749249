import api from "../api/api";

const TimeService = {
  getTimeOffset: async () => {
    const requestTime = Date.now();
    const res = await api.get(`api/v1/times`);
    const responseTime = Date.now();
    const precision = (responseTime - requestTime) / 2;
    return res.data + precision - responseTime;
  },
};

export default TimeService;
