import { useEffect } from "react";
import useMounted from "./useMounted";

const useCleanUp = (cleanup) => {
  const mounted = useMounted();

  useEffect(() => {
    if (!mounted) {
      cleanup();
    }
  }, [cleanup, mounted]);
};

export default useCleanUp;
